
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VideoModal',
  props: {
    videoId: {
      type: String,
      required: true
    }
  }
})
