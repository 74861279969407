import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "youtube-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "change-youtube",
        onClick: ($event: any) => (_ctx.$emit('selectVideo', item))
      }, [
        (item.snippet?.thumbnails?.medium?.url)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              style: {"width":"100%"},
              src: item.snippet.thumbnails.medium.url,
              alt: "Video Thumbnail"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("p", null, _toDisplayString(item.snippet?.title || 'Title not available'), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}