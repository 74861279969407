import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "close"
      }, "×"),
      (_ctx.videoId)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 0,
            src: `https://www.youtube.com/embed/${_ctx.videoId}?autoplay=1&rel=0&origin=http://bv.ck.ua`,
            frameborder: "0",
            allowfullscreen: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}