import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ width: _ctx.isOpen ? '250px' : '0' }),
    class: "sidenav"
  }, [
    _createElementVNode("a", {
      href: "javascript:void(0)",
      class: "closebtn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, "×"),
    _createElementVNode("a", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selectList', 'UUSb71yKJmS0eHyhRRl00ioQ'))),
      class: "nav-link"
    }, "ВСІ"),
    _createElementVNode("a", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('selectList', 'PLlURDWJlf7fQA1Pp2nyFBy9PTqJTpB5XL'))),
      class: "nav-link"
    }, "ПІСНІ"),
    _createElementVNode("a", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('selectList', 'PLlURDWJlf7fS8-Z9hz4ShqtXdjg2tIGil'))),
      class: "nav-link"
    }, "ПРОПОВІДІ"),
    _createElementVNode("a", {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('selectList', 'PLlURDWJlf7fTuF3VfkKrsesTtfQtuNwo9'))),
      class: "nav-link"
    }, "ЖИТТЯ")
  ], 4))
}