
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'VideoList',
  props: {
    videos: {
      type: Array as PropType<any[]>,
      required: true
    }
  }
})
