import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "jumbotron" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_VideoList = _resolveComponent("VideoList")!
  const _component_VideoModal = _resolveComponent("VideoModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.textBible.title), 1),
        _createElementVNode("h1", null, _toDisplayString(_ctx.textBible.verse), 1)
      ])
    ]),
    _createVNode(_component_SideNav, {
      "is-open": _ctx.isSideNavOpen,
      onOpen: _ctx.openNav,
      onClose: _ctx.closeNav,
      onSelectList: _ctx.listVideo
    }, null, 8, ["is-open", "onOpen", "onClose", "onSelectList"]),
    _createElementVNode("div", {
      class: "btn_mobile",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNav && _ctx.toggleNav(...args)))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("p", null, [
        _createElementVNode("i", { class: "fas fa-align-justify" }),
        _createTextVNode(" МЕНЮ ВІДЕО "),
        _createElementVNode("i", { class: "fas fa-video" })
      ], -1)
    ])),
    _createVNode(_component_VideoList, {
      videos: _ctx.filteredVideoList,
      onSelectVideo: _ctx.selectVideo
    }, null, 8, ["videos", "onSelectVideo"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_VideoModal, {
          key: 0,
          "video-id": _ctx.videoModal,
          onClose: _ctx.closeModal
        }, null, 8, ["video-id", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.countVideo && _ctx.countVideo(...args))),
      class: "callback-bt"
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "text-call" }, [
        _createElementVNode("i", { class: "fas fa-plus" }),
        _createElementVNode("span", null, [
          _createTextVNode("додати 5"),
          _createElementVNode("br"),
          _createTextVNode("відео ")
        ])
      ], -1)
    ]))
  ]))
}